import type { AnchorHTMLAttributes, MouseEvent } from 'react';
import Button, { type ButtonCommonProps } from '#components/shared/ui/Button';
import type { AnalyticsEventPrimaryOptions } from '#types/Analytics';
import analytics from '#utils/analytics.ts';

type Props = ButtonCommonProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    event: AnalyticsEventPrimaryOptions;
  };

const ButtonLikeLinkWithAnalytics = ({ event, onClick, ...props }: Props) => {
  return (
    <Button
      {...props}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (onClick) onClick(e);
        analytics.event(event);
      }}
    ></Button>
  );
};

export default ButtonLikeLinkWithAnalytics;
